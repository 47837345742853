export default {
    name: 'Error',
    data() {
        return {
            url: undefined
        }
    },
    created() {
        const base = process.env.NODE_ENV == 'development' ? 'http://localhost:3000/' : 'https://verwijslink.nl/'
        const id = this.$route.query.id
        if (id) this.url = base + id
    }
}
