import api from "@/services/api"

export default {
    name: 'Home',
    data() {
        return {
            url: '',
            protocol: '',
            protocols: ['https://', 'http://'],
            submitStatus: '',
            redirectUrl: '',
            qrCode: ''
        }
    },
    methods: {
        async createRedirect() {
            if (!this.url) {
                this.submitStatus = "NO_URL"
            } else {
                this.submitStatus = "PENDING"
                const uri = this.protocol + this.url
                const res = await api.post('/redirects', {url: uri})

                if (res.origin_url) {
                    this.redirectUrl = res.origin_url
                    this.qrCode = res.qr_code
                    this.submitStatus = "OK"
                } else {
                    this.submitStatus = "ERROR"
                }
            }
        }
    },
    mounted() {
        this.protocol = this.protocols[0]
    }
}
